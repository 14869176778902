import React from "react";
import {graphql} from 'gatsby';
import PostContent from './Post/';
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const BlogPostEn = (props) => {
  return (
    <Layout 
    lang="en-CA" 
    switchLink={'/blogue/' + props.pageContext.next.slug}
    switchText='FR' 
    slider={false} 
    title={props.data.contentfulArticleDeBlogue.title}>
      <Seo 
        title={props.data.contentfulArticleDeBlogue.title}
        description={props.data.contentfulArticleDeBlogue.excerpt}
       />
       <PostContent lang="en-CA" data={props.data.contentfulArticleDeBlogue} latests={props.data.allContentfulArticleDeBlogue.edges}/>
    </Layout>
  )
}

export default BlogPostEn

export const pageQuery = graphql`
  query BlogPostBySlugEn($slug: String!) {
    contentfulArticleDeBlogue( slug: { eq: $slug }) {
      title
      createdAt(locale: "en", formatString: "MMMM Do YYYY")
      content {
        raw
        references {
          ... on ContentfulAsset {
              contentful_id
              __typename
              gatsbyImageData(layout: CONSTRAINED)
            }
        }
      }
      headerImg {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    allContentfulArticleDeBlogue(limit: 2, filter: {node_locale: {eq: "en-CA"}}) {
      edges {
        node {
          title
          createdAt(locale: "en", formatString: "MMMM Do YYYY")
          excerpt
          slug
          headerImg {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`

